import ApiCommerce from '@/core/apis/ApiCommerce';
import { IDadosLogin } from '@/core/models/IDadosLogin';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IResposta } from '@/core/models/IResposta';
import { IRetornoAutenticacao } from '@/core/models/IRetornoAutenticacao';
import { IDTOUsuarioMeusDados } from '@/models/DTO/Usuarios/IDTOUsuarioMeusDados';
import { IConfiguracao } from '@/models/Entidades/IConfiguracao';
import { IIntegracao } from '@/models/Entidades/IIntegracao';
import { IParceiro } from '@/models/Entidades/IParceiro';
import { IUsuario } from '@/models/Entidades/IUsuario';
import { ETipoNotificacaoEmail } from '@/models/Enumeradores/ETipoNotificacaoEmail';

class ServicoApp {
    private endPoint = 'app';

    private apiCommerce = new ApiCommerce();

    public async obterDataAtual(): Promise<string> {
      const result: any = await this.apiCommerce.get(`${this.endPoint}/data-atual`);
      return result.data;
    }

    public async login(dadosLogin: IDadosLogin): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiCommerce.post('autenticacao/login', dadosLogin);
      return result.data;
    }

    public async obterDadosUsuarioAutenticado(): Promise<IUsuario> {
      const result: any = await this.apiCommerce.get('autenticacao/dados');
      return result.data;
    }

    public async atualizarDadosUsuarioAutenticado(meusDados: IDTOUsuarioMeusDados): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('autenticacao/dados', meusDados);
      return result.data;
    }

    public async atualizarToken(): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiCommerce.post('autenticacao/atualizar-token');
      return result.data;
    }

    public async deslogar(): Promise<IRetornoAutenticacao> {
      const result: any = await this.apiCommerce.post('autenticacao/logout');
      return result.data;
    }

    public async obterConfiguracoes(): Promise<IConfiguracao> {
      const result: any = await this.apiCommerce.get('configuracoes');
      return result.data;
    }

    public async atualizarConfiguracoes(configuracoes: IConfiguracao): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('configuracoes', configuracoes);
      return result.data;
    }

    public async atualizarConfiguracoesEmails(configuracoes: IConfiguracao): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('configuracoes/emails', configuracoes);
      return result.data;
    }

    public async obterDadosMeuNegocio(): Promise<IParceiro> {
      const result: any = await this.apiCommerce.get('meu-negocio');
      return result.data;
    }

    public async atualizarDadosMeuNegocio(parceiro: IParceiro): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('meu-negocio', parceiro);
      return result.data;
    }

    public async obterIntegracoesErp(): Promise<IIntegracao[]> {
      const result: any = await this.apiCommerce.get('integracoes/erps');
      return result.data;
    }

    public async obterIntegracoesMarketPlaces(): Promise<IIntegracao[]> {
      const result: any = await this.apiCommerce.get('integracoes/marketplaces');
      return result.data;
    }

    public async atualizarIntegracao(integracao: IIntegracao): Promise<IResposta> {
      const result: any = await this.apiCommerce.put('integracoes', integracao);
      return result.data;
    }

    public async sincronizarInformacoes(codigoIntegracao: number): Promise<IResposta> {
      const result: any = await this.apiCommerce.post(`integracoes/${codigoIntegracao}/sincronizar`);
      return result.data;
    }

    public async testarComunicacao(integracao: IIntegracao): Promise<IResposta> {
      const result: any = await this.apiCommerce.post('integracoes/testar-comunicacao', integracao);
      return result.data;
    }

    public async limparCache(): Promise<IResposta> {
      const result: any = await this.apiCommerce.post('configuracoes/limpar-cache');
      return result.data;
    }

    public obterDescricaoTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail):string {
      let descricao = '';
      switch (tipoNotificacao) {
        case ETipoNotificacaoEmail.Padrao:
          descricao = 'Padrão';
          break;

        case ETipoNotificacaoEmail.NovasContas:
          descricao = 'Novas Contas';
          break;

        case ETipoNotificacaoEmail.BoasVindas:
          descricao = 'Boas Vindas';
          break;

        case ETipoNotificacaoEmail.AtivacaoConta:
          descricao = 'Ativação de Conta';
          break;

        case ETipoNotificacaoEmail.RedefinirSenha:
          descricao = 'Redefinir Senha';
          break;

        case ETipoNotificacaoEmail.Contatos:
          descricao = 'Contatos';
          break;

        case ETipoNotificacaoEmail.Newsletter:
          descricao = 'Newsletter';
          break;

        case ETipoNotificacaoEmail.NovosPedidos:
          descricao = 'Novos Pedidos';
          break;

        case ETipoNotificacaoEmail.PedidoCancelado:
          descricao = 'Pedido Cancelado';
          break;

        case ETipoNotificacaoEmail.PedidoAtualizado:
          descricao = 'Pedido Atualizado';
          break;

        default:
          break;
      }
      return descricao;
    }

    public obterDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail, destinatarios:string):string {
      let descricaoDestinatarios = '';
      switch (tipoNotificacao) {
        case ETipoNotificacaoEmail.Padrao:
          descricaoDestinatarios = 'Clientes e Administradores';
          break;

        case ETipoNotificacaoEmail.NovasContas:
        case ETipoNotificacaoEmail.Contatos:
        case ETipoNotificacaoEmail.Newsletter:
          descricaoDestinatarios = destinatarios;
          break;

        case ETipoNotificacaoEmail.BoasVindas:
        case ETipoNotificacaoEmail.AtivacaoConta:
        case ETipoNotificacaoEmail.RedefinirSenha:
          descricaoDestinatarios = 'Cliente';
          break;

        case ETipoNotificacaoEmail.NovosPedidos:
        case ETipoNotificacaoEmail.PedidoCancelado:
        case ETipoNotificacaoEmail.PedidoAtualizado:
          descricaoDestinatarios = `Cliente e: ${destinatarios}`;
          break;

        default:
          break;
      }
      return descricaoDestinatarios;
    }

    public preencherDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail):boolean {
      let preencher = false;
      switch (tipoNotificacao) {
        case ETipoNotificacaoEmail.NovasContas:
        case ETipoNotificacaoEmail.Contatos:
        case ETipoNotificacaoEmail.Newsletter:
        case ETipoNotificacaoEmail.NovosPedidos:
        case ETipoNotificacaoEmail.PedidoCancelado:
        case ETipoNotificacaoEmail.PedidoAtualizado:
          preencher = true;
          break;

        case ETipoNotificacaoEmail.Padrao:
        case ETipoNotificacaoEmail.BoasVindas:
        case ETipoNotificacaoEmail.AtivacaoConta:
        case ETipoNotificacaoEmail.RedefinirSenha:
          preencher = false;
          break;

        default:
          break;
      }
      return preencher;
    }
}
export default ServicoApp;
